<script>
import { setComputed } from '@state/helpers'

import { getScrollTop } from '@utils/scroll'

export default {
  data() {
    return {
      hostname: `${location.protocol}//${window.location.hostname}`,
      menuList: [
        {
          title: this.$i18n.t('global.NAV_HOME'),
          alias: "home",
          path: "/",
          hidden: 0,
          disabled: 0
        },
        {
          title: this.$i18n.t('global.NAV_MENU'),
          alias: "menu",
          path: "/menu",
          hidden: 0,
          disabled: 0
        },
        {
          "title": this.$i18n.t('global.NAV_STORE'),
          "alias": "branch",
          path: "/branch",
          params: {
            branchAlias: this.$route.params.lang === "zh-TW"? 'daan-1':'daan-1-en'
          },
          hidden: 0,
          disabled: 0,
        },
        {
          "title": this.$i18n.t('global.NAV_FAQ'),
          "alias": "faq",
          path: "/faq",
          hidden: 0,
          disabled: 0
        },
        {
          "title": this.$i18n.t('global.NAV_BOOKING'),
          "alias": "booking",
          path: "/booking",
          hidden: 0,
          disabled: 0
        },
        {
          "title": this.$i18n.t('global.NAV_GROUP'),
          type: "mainWebsite",
          hidden: 0,
          disabled: 0
        }
      ],
      showHamber: false,
      showMega: false,
      scrollTop: this.getScrollTop,
    }
  },
  computed: {
    ...setComputed,
  },
  destroyed() {
    document.removeEventListener("scroll", this.onScroll, { passive: false });
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll, { passive: false });
  },
  methods: {
    setLanguage(lang) {
      /* this.$store
        .dispatch('set/setLanguage', {
          language: lang
        }) */
      if(lang !== this.currentLanguage){
        window.location = `${this.$route.path}?language=${lang}`
      }
    },
    formatLanguage(lang) {
      const langList = {
        'en': 'en',
        'zh-TW': '中'
      };

      return langList[lang]
    },
    formatLanguageUrl() {
      const langList = {
        'en': 'en',
        'zh-TW': 'zh-TW'
      };

      return langList[this.currentLanguage]
    },
    onClickNavbarOutside() {
      this.showHamber = false
    },
    onScroll() {
      this.scrollTop = this.getScrollTop()
    },
    onImgError(e) {
      /* setTimeout(()=> {
        e.target.src = `${e.target.src}?e`;
      }, 100);

      console.error('圖片載入失敗: ', e.target.src); */
    },
    getScrollTop
  },
  
}
</script>

<template>
  <header :class="[$style.header, { [$style.isScroll]: scrollTop > 70 }]" v-click-outside="onClickNavbarOutside">
    <nav :class="$style.navbar" class="navbar navbar-expand-lg align-items-center justify-content-between">
      <router-link :class="$style.navbarBrand" class="navbar-brand" :to="{ name: 'home' }">
        <img :class="$style.logo" src="~@assets/images/logo.svg" @error="onImgError($event)">
      </router-link>
      <div class="navbar-collapse"
           :class="[$style.navbarCollapse, { [$style.isShow]: showHamber }]"
      >
        <ul :class="$style.navbarNav" class="navbar-nav m-auto">
          <template v-for="(menuItem, $index) in menuList">
            <li :class="$style.navItem" :key="$index" class="nav-item">
              <template v-if="menuItem.type === 'link'">
                <a target="_blank"  :class="$style.navLink" :href="menuItem.link">{{menuItem.title}}</a>
              </template>
              <template v-else-if="menuItem.type === 'mainWebsite'">
                <a target="_blank"  :class="$style.navLink" :href="currentWebsite.main">{{menuItem.title}}</a>
              </template>
              <template v-else-if="menuItem.children">
                <span
                  :class="[$style.navLink, $style.hasChildren, { [$style.isActive]: $route.name ===
                  menuItem.active_alias }]"
                  class="nav-link"
                >
                  {{menuItem.title}}
                </span>
                <ul :class="$style.subNavbarNav" class="navbar-nav">
                  <li v-for="(subMenuItem, $index2) in menuItem.children"
                      :key="$index2"
                      :class="$style.subNavItem"
                      class="nav-item">
                    <router-link
                      :class="$style.navLink"
                      class="nav-link"
                      exact
                      :to="{ name: menuItem.alias, params: menuItem.params }"
                      :active-class="$style.isActive"
                    >
                      {{subMenuItem.title}}
                    </router-link>
                  </li>
                </ul>
              </template>
              <template v-else>
                <a
                  v-if="menuItem.params"
                  :class="[$style.navLink, { [$style.isActive]: $route.name === menuItem.alias }]"
                  class="nav-link"
                  exact
                  :href="`/${formatLanguageUrl()}${menuItem.path}/${menuItem.params.branchAlias}`"
                  :active-class="$style.isActive"
                >
                  {{menuItem.title}}
                </a>
                <a
                  v-else
                  :class="[$style.navLink, { [$style.isActive]: $route.name === menuItem.alias }]"
                  class="nav-link"
                  exact
                  :href="`/${formatLanguageUrl()}${menuItem.path}`"
                  :active-class="$style.isActive"
                >
                  {{menuItem.title}}
                </a>
                <!--<router-link
                  :class="$style.navLink"
                  class="nav-link"
                  exact
                  :to="{ name: menuItem.alias, params: menuItem.params }"
                  :active-class="$style.isActive"
                >
                  {{menuItem.title}}
                </router-link>-->
              </template>
            </li>
          </template>
        </ul>
      </div>
      <div :class="$style.navRight" class="d-flex align-items-center justify-content-end">
        <!--<button class="btn btn-outline-light isCircle my-2 my-sm-0" type="submit">{{formatLanguage(currentLanguage)}}</button>-->
        <button @click="showHamber = !showHamber" :class="$style.navbarToggle">
          <img v-if="showHamber" :class="$style.close" src="@assets/images/icon/close.svg" @error="onImgEror($event)">
          <img v-else src="@assets/images/icon/hamburger.svg" @error="onImgError($event)">
        </button>
      </div>
    </nav>
  </header>
</template>
<style lang="scss" module>

  .header {
    height: 130px;
    z-index: 4;
    position: relative;
    @include touch {
      height: 70px;
    }
    &.isScroll {
      height: 92px;
      @include touch {
        height: 70px;
      }
      .navbarBrand, .navRight {
        height: 44px;
        .logo {
          margin-top: -11px;
          height: 55px;
          @include touch {
            height: 42px;
            margin-top: 0;
          }
        }
      }
      .megaDropdown {
        & > .megaMenu {
          top: 92px;
        }
      }
    }
  }
  .navbar {
    padding: 21px 44px;
    background: rgba(#000, 0.75);
    position: fixed;
    width: 100vw;
    @include touch {
      padding: 0.5rem 1rem;
    }
  }
  .navbarBrand, .navRight {
    position: relative;
    z-index: 2;
    width: 88px;
    height: 88px;
    transition: height 0.3s;
    @include touch {
      height: 54px !important;
    }
    .logo {
      transition: height 0.3s;
      height: 80px;
      @include touch {
        height: 44px;
      }
    }
  }
  .navRight {
    width: 88px;
    height: 88px;
    @include touch {
      height: 54px !important;
    }
  }
  .navbarToggle {
    appearance: none;
    background: transparent;
    border: 0px solid transparent;
    color: #fff;
    position: relative;
    z-index: 1;
    font-size: 30px;
    padding: 0;
    width: 35px;
    text-align: center;
    line-height: 5px;
    .close {
      color: #000;
      width: 16px;
      height: 16px;
      @include touch {
        width: 25px;
        height: 25px;
      }
    }
    &:focus {
      outline: none;
    }
  }
  .navbarCollapse {
    pointer-events: none;
    position: absolute;
    top: 0px;
    width: 100vw;
    right: 0;
    background: #e36b00;
    background-image: linear-gradient(136deg, #ed6c00 -58%, #e36b00 31%, #c15c00 110%);
    padding: 3rem 0rem;
    height: 100vh;
    transition: transform 0.3s ease-in-out;
    transform: translateX(100%);
    z-index: 1;
    overflow-y: scroll;
    overscroll-behavior: contain;
    width: 260px;
    @include touch {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .navbarNav {
      flex-direction: column;
      padding: 6rem 0;
      @include touch {
        text-align: center;
      }
    }
    &.isShow {
      pointer-events: auto;
      opacity: 1;
      transform: translateX(0);
    }
  }
  .navItem {
    &:hover {
      .navLink:not(.isActive) {
        color: #fff;
        text-decoration: none;
        &:active {
          opacity: 0.5;
        }
      }
    }
    > .subNavbarNav {
      display: none;
      flex-direction: column;
      & > .subNavItem {
        position: relative;
        right: -18px;
        .navLink {
          // font-size: 16px;
        }
      }
    }
    /* &:hover {
      & > .subNavbarNav {
        display: block;
      }
      .navLink {
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          background: #fff;
          border-radius: 100%;
          position: absolute;
          left: 1rem;
          top: 50%;
          margin-top: -4px;
        }
      }
    } */

  }
  .navLink {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
    color: #000;
    position: relative;
    font-weight: 500;
    letter-spacing: 3px;
    margin: 1rem 0;
    display: inline-block;
    font-size: 16px;
    @include touch {
      display: block !important;
      font-size: 18px;
      line-height: 60px;
      letter-spacing: 3.24px;
      text-align: center;
      padding: 0;
      margin: 0;
    }
    &.hasChildren {
      position: relative;
      &:before {
        content: '+';
        position: absolute;
        left: 1.2rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.isActive {
      color: #fff;
    }
  }
</style>
