import axios from "axios";
axios.defaults.baseURL = '/api';

export default [
  {
    path: '/:lang',
    name: 'home',
    component: () => lazyLoadView(import('@views/home')),
  },
  {
    path: '/:lang/menu',
    name: 'menu',
    component: () => lazyLoadView(import('@views/menu')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        try {
          let alias = "orangeshabushabu-menu"
          const lang = routeTo.params.lang;
          if(lang !== "zh-TW" && lang !== "zh-Hant") {
            alias += "-en"
          }
          await axios.get('/module/'+alias).then((response) => {
            routeTo.params.menuModule =  response.data.data.items.items.data;
          });
        } catch (error) {
          next({ name: '404' })
        }
        next()
      },
    },
    props: (route) => ({
      menuModule: route.params.menuModule || {},
    }),
  },
  {
    path: '/:lang/branch/:branchAlias',
    name: 'branch',
    component: () => lazyLoadView(import('@views/branch')),
    meta: {
      async beforeResolve(routeTo, routeFrom, next) {
        try {
          let alias = "store-shabushabu"
          const lang = routeTo.params.lang;
          if(lang !== "zh-TW" && lang !== "zh-Hant") {
            alias += "-en"
          }
          await axios.post(`/item/search`, {
            category_alias: [alias],
            order: "asc"
          }).then((response) => {
            routeTo.params.branchList =  response.data.data.items;
          });

          await axios.get(`/item/${routeTo.params.branchAlias}`).then((response) => {
            routeTo.params.branchItem = response.data.data.items;
          });

          // routeTo.params.banner = [
          //   {
          //       "path": "/storage/media/orangeshabushabu/branch/daan-1/slideshow/slide1.jpg",
          //       "type": "image/jpeg"
          //   },
          //   {
          //       "path": "/storage/media/orangeshabushabu/branch/daan-1/slideshow/slide2.jpg",
          //       "type": "image/jpeg"
          //   },
          //   {
          //       "path": "/storage/media/orangeshabushabu/branch/daan-1/slideshow/slide3.jpg",
          //       "type": "image/jpeg"
          //   },
          //   {
          //       "path": "/storage/media/orangeshabushabu/branch/daan-1/slideshow/slide4.jpg",
          //       "type": "image/jpeg"
          //   }
          // ]

          await axios.post(`/media/folderFiles`, {
            dir: routeTo.params.branchItem.extrafields.store_media.value
          }).then((response) => {
            if(response.data.data.items.length > 0){
              routeTo.params.banner = response.data.data.items ;
            }
          });

        } catch (error) {
          next({ name: '404' })
        }
        next()
      },
    },
    props: (route) => ({
      branchList: route.params.branchList || [],
      branchItem: route.params.branchItem || {},
      banner: route.params.banner,
    }),
  },
  {
    path: '/:lang/faq',
    name: 'faq',
    component: () => lazyLoadView(import('@views/faq')),
  },
  {
    path: '/:lang/booking',
    name: 'booking',
    component: () => lazyLoadView(import('@views/booking')),
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404').default,
    props: true,
  },
  {
    path: '*',
    redirect: '/404',
  },
]


function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    loading: require('@views/_loading').default,
    delay: 1000,
    error: require('@views/_timeout').default,
    timeout: 10000,
  });

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      return h(AsyncHandler, data, children)
    },
  })
}
