import Vue from 'vue'
import "babel-polyfill";
import VueAnalytics from 'vue-analytics'
import App from './App'
import "./directive";
import router from '@router'
import i18n from '@lang';
import store from '@state/store'
import '@components/_globals'

import Buefy from 'buefy'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {faCalendar, faUserFriends, faPlus, faMinus, faTimes, faPaperclip, faBars, faCircleNotch} from "@fortawesome/pro-light-svg-icons"
import {faCaretLeft, faCaretRight, faCaretDown, faPlay} from "@fortawesome/free-solid-svg-icons"
import {faFacebookF, faTwitter} from "@fortawesome/free-brands-svg-icons"

// https://www.npmjs.com/package/ks-vue-scrollmagic
import KsVueScrollmagic from "ks-vue-scrollmagic";
Vue.use(KsVueScrollmagic);

library.add(
  faCalendar,faUserFriends, faPlus, faMinus, faTimes, faPaperclip, faBars, faCircleNotch,
  faCaretLeft, faCaretRight, faCaretDown, faPlay,
  faFacebookF, faTwitter,
  );
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fal',
});

import { MessageBox, Select, Option, Input, Form, FormItem, Upload } from 'element-ui';
import 'element-ui/lib/theme-chalk/message-box.css';
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;

Vue.use(Select);
Vue.use(Option);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Upload);


// https://www.npmjs.com/package/vue-fb-customer-chat
// import VueFbCustomerChat from 'vue-fb-customer-chat'

// Vue.use(VueFbCustomerChat, {
//   // page_id: '1961210497465503',
//   page_id: '275022133319',
//   theme_color: '#e85400',
//   locale: 'zh_TW',
// });


// Don't warn about using the dev version of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production';

// If running inside Cypress...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException
}

Vue.use(VueAnalytics, {
  id: ['UA-159168245-2'],
  disableScriptLoader: true,
  router,
  autoTracking: {
    pageviewOnLoad: false,
  },
});

const app = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// If running e2e tests...
if (process.env.VUE_APP_TEST === 'e2e') {
  // Attach the app to the window, which can be useful
  // for manually setting state in Cypress commands
  // such as `cy.logIn()`.
  window.__app__ = app
}
