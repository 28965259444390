import { mapState } from 'vuex'

export const setComputed = {
  ...mapState('set', {
    currentLanguage: (state) => state.cachedLanguage,
    currentWebsite: (state) => state.cachedWebsite,
  }),
};

export const videoComputed = {
  ...mapState('video', {
    currentVideo: (state) => state.cachedVideo,
    currentPreloadDone: (state) => state.cachedPreloadDone,
  }),
};
